import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  Grid,
  Checkbox,
  Typography,
  Divider,
} from '@material-ui/core'

import BaseDialog from '../../BaseDialog'

import { useDataApi } from '../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../context/NotificationsContext'
import ImagePicker from '../../ImagePicker'
import AutoCompleteInput from '../../AutoCompleteInput'

import useStyles from './styles'

export default function CreateControlItemDialog({
  open,
  onClose,
  onCreated,
  controlItemsListId,
  belowItemWithId,
}) {
  const { t } = useTranslation()

  const classes = useStyles()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [controlItemType, setControlItemType] = useState(null)
  const [controlItemGroup, setControlItemGroup] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [images, setImages] = useState([])
  const [isuzuPdi, setIzuzuPdi] = useState(false)
  const [mandatoryPhoto, setMandatoryPhoto] = useState(false)
  const [photoItem, setPhotoItem] = useState(false)
  const [disableNonConformityReport, setDisableNonConformityReport] = useState(false)
  const [verifyPresence, setVerifyPresence] = useState(false)

  const handleCheckboxChange = (checkboxName) => (evt) => {
    // Uncheck all other checkboxes when one is checked
    setIzuzuPdi(false)
    setPhotoItem(false)
    setDisableNonConformityReport(false)
    setVerifyPresence(false)

    // Check the selected checkbox
    switch (checkboxName) {
      case 'isuzuPdi':
        setIzuzuPdi(evt.target.checked)
        break
      case 'photoItem':
        setPhotoItem(evt.target.checked)
        break
      case 'disableNonConformityReport':
        setDisableNonConformityReport(evt.target.checked)
        break
      case 'verifyPresence':
        setVerifyPresence(evt.target.checked)
        break
      default:
        break
    }
  }

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setControlItemType(null)
    setControlItemGroup(null)
    setTitle('')
    setDescription('')
    setImages([])
    setIzuzuPdi(false)
    setMandatoryPhoto(false)
    setPhotoItem(false)
    setDisableNonConformityReport(false)
    setVerifyPresence(false)
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (!controlItemType) {
      setError('controlItemType_required')
      return
    }

    if (!controlItemGroup) {
      setError('controlItemGroup_required')
      return
    }

    if (title.length === 0) {
      setError('title_required')
      return
    }

    if (description.length === 0) {
      setError('description_required')
      return
    }

    const data = {
      controlItemsListId,
      controlItemTypeId: controlItemType.id,
      controlItemGroupId: controlItemGroup.id,
      title,
      description,
      isuzuPdi,
      mandatoryPhoto,
      photoItem,
      disableNonConformityReport,
      verifyPresence,
    }
    if (belowItemWithId) {
      data.belowItemWithId = belowItemWithId
    }
    setLoading(true)
    let promise = Promise.resolve()
    if (Array.isArray(images) && images.length > 0) {
      promise = Promise.all(images.map((image) => dataProvider.upload({ file: image })
        .then((response) => response.photoUrl)))
        .then((urls) => {
          data.photos = urls
        })
    }

    promise.then(() => dataProvider.create('control-items', { data }))
      .then(() => {
        dataProvider.cache.reset()
        onCreated()
        showNotification('success', t('create-control-item-dialog.success'))
      }).catch(() => {
        showNotification('error', t('errors.api-error'))
      }).then(() => resetState())
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('create-control-item-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <AutoCompleteInput
            onChange={setControlItemType}
            value={controlItemType}
            label={t('create-control-item-dialog.types')}
            resource="/control-items/type-autocomplete"
            error={error === 'controlItemType_required'}
            helperText={t('common.required')}
            query={{ q: '' }}
            required
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setControlItemGroup}
            value={controlItemGroup}
            label={t('create-control-item-dialog.groups')}
            resource="/control-items/group-autocomplete"
            error={error === 'controlItemGroup_required'}
            helperText={t('common.required')}
            query={{ q: '' }}
            required
            canDelete
          />
        </Grid>
        <Grid item>
          <TextField
            required
            error={error === 'title_required'}
            helperText={t('common.required')}
            id="title"
            label={t('common.title')}
            fullWidth
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            error={error === 'description_required'}
            helperText={t('common.required')}
            id="description"
            label={t('common.description')}
            multiline
            fullWidth
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography className={classes.sectionTitle}>{t('create-control-item-dialog.control-item-type-title')}</Typography>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>{t('common.isuzuPdi')}</Typography>
            </Grid>
            <Grid item>
              <Checkbox color="primary" onChange={handleCheckboxChange('isuzuPdi')} checked={isuzuPdi} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>{t('create-control-item-dialog.photo-item')}</Typography>
            </Grid>
            <Grid item>
              <Checkbox color="primary" onChange={handleCheckboxChange('photoItem')} checked={photoItem} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>{t('create-control-item-dialog.disable-non-conformity-report')}</Typography>
            </Grid>
            <Grid item>
              <Checkbox color="primary" onChange={handleCheckboxChange('disableNonConformityReport')} checked={disableNonConformityReport} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>{t('create-control-item-dialog.verify-presence')}</Typography>
            </Grid>
            <Grid item>
              <Checkbox color="primary" onChange={handleCheckboxChange('verifyPresence')} checked={verifyPresence} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>{t('create-control-item-dialog.mandatory-photo')}</Typography>
            </Grid>
            <Grid item>
              <Checkbox color="primary" onChange={(evt) => setMandatoryPhoto(evt.target.checked)} checked={mandatoryPhoto} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
        >
          <ImagePicker
            onChange={setImages}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
