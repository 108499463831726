import React from 'react'

import { ReactComponent as CheckIcon } from '../../assets/Icons_Green_Check.svg'

export default function generate({
  t, classes, onShowPhotos, items
}) {
  return [
    {
      name: 'controlItem.controlItemType.name',
      label: t('common.typology'),
      options: {
        sort: false,
      }
    },
    {
      name: 'controlItem.title',
      label: t('controls-detail.item-description-title'),
      options: {
        sort: false,
      }
    },
    {
      name: 'conformityType',
      label: t('controls-detail.item-non-conformity-title'),
      options: { display: false, viewColumns: false, filter: false, sort: false, searchable: false, print: false, download: false },
    },
    {
      name: 'description',
      label: t('controls-detail.item-non-conformity-title'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (val && tableMeta.rowData.length > 2 && tableMeta.rowData[2].title) {
            // eslint-disable-next-line max-len
            return [tableMeta.rowData[2].title, val].join(' - ')
          }

          return val || ' '
        },
      }
    },
    {
      name: 'fixSuggestionType.title',
      label: t('controls-detail.fix-suggestion-title'),
      options: {
        sort: false,
      }
    },
    {
      name: 'controlItem.isuzuPdi',
      label: t('controls-detail.isuzu-pdi-title'),
      options: {
        sort: false,
        customBodyRender: (val) => {
          if (val) {
            return (
              <CheckIcon />
            )
          }
          return ' '
        },
      },
    },
    {
      name: 'photos',
      label: t('common.photo'),
      options: {
        sort: false,
        customBodyRender: (val) => {
          if (val.length > 0) {
            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <img alt="vehicle" src={val[0]} className={classes.itemPhoto} onClick={() => onShowPhotos(val)} onKeyDown={() => onShowPhotos(val)} crossOrigin="anonymous" />
            )
          }
          return t('common.noPhoto')
        },
      },
    },
  ]
}
