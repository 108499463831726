import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  selector: {
    width: '100%',
  },
  image: {
    width: '100%',
  },
  sectionTitle: {
    fontWeight: 'bold',
  },
}))
