import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core'

import {
  useParams,
} from 'react-router-dom'

import dateFnsFormat from 'date-fns/format'
import Loading from '../../components/Loading'
import useStyles from './styles'
import Table from '../../components/Table'
import generateItemsColumns from './columns-items'

import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'

import PhotoGaleryDialog from '../../components/PhotoGaleryDialog'

export default function ControlsDetail() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { id } = useParams()

  const [control, setControl] = useState({})
  const [loading, setLoading] = useState(true)
  const [photos, setPhotos] = useState([])
  const [openPhotoGalleryDialog, setOpenPhotoGalleryDialog] = useState(false)

  const [loadingItems, setLoadingItems] = useState(true)
  const [itemsTableState] = useState({
    page: 0,
    sort: null,
  })
  const [itemsTableData, setItemsTableData] = useState(null)

  useEffect(() => {
    dataProvider.getOne('control', { id })
      .then((response) => {
        setControl(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    dataProvider.control.getItems(
      id,
      true,
    ).then((response) => {
      setItemsTableData(response.data)
      setLoadingItems(false)
    }).catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [itemsTableState, id])

  const onShowPhotos = (photosToShow) => {
    setPhotos(photosToShow)
    setOpenPhotoGalleryDialog(true)
  }

  const itemsTableColumns = generateItemsColumns({
    t,
    classes,
    onShowPhotos,
  })

  if (loading || loadingItems) {
    return <Loading />
  }
  const {
    conformity,
    controlType,
    submittedByUser,
    controlDate,
    reportState,
    reportUrl,
    vehicle: {
      client,
      vehicleType,
      plate,
      nIntern,
      nSerie,
    },
  } = control

  return (
    <Grid container direction="column">
      <PhotoGaleryDialog
        open={openPhotoGalleryDialog}
        onClose={() => setOpenPhotoGalleryDialog(false)}
        images={photos}
      />
      <Grid container direction="row" justifyContent="space-between">
        <Grid item container direction="column" xs={12} md={8}>
          <Typography variant="h4">{controlType.name}</Typography>
          <Typography variant="h5">
            {vehicleType.title}
            {' '}
            {nSerie}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={4} alignItems="flex-end" justifyContent="flex-end">
          {reportState === 1 && reportUrl && (
            <Grid item>
              <Button
                href={reportUrl}
                target="_blank"
                fullWidth
                color="primary"
                variant="contained"
              >
                {t('controls-detail.download-control-report')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.firstSection} direction="column">
        <Grid
          container
          className={[classes.firstSectionSecondSubSection, classes.sectionBottomBorder].join(' ')}
          direction="column"
          spacing={2}
        >
          <Grid container item>
            <Grid container justifyContent="space-between">
              <Grid container item spacing={2} xs={6}>
                <Grid item>
                  <Typography variant="h6">{t('common.date')}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" className={classes.textBlack}>
                    {dateFnsFormat(
                      Date.parse(controlDate),
                      'dd/MM/yyyy H:mm',
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item spacing={2} justifyContent="flex-end" xs={6}>
                <Grid item>
                  <Typography variant="h6">{t('common.conformity')}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" className={classes.textBlack}>
                    {conformity.title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid container justifyContent="space-between">
              <Grid container item spacing={2} xs={5}>
                <Grid item>
                  <Typography variant="h6">{t('common.controlBy')}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" className={classes.textBlack}>
                    {submittedByUser.fullName}
                  </Typography>
                </Grid>
              </Grid>
              {control.timeFormattedCounted ? (
                <Grid container item spacing={2} justifyContent="flex-end" xs={6}>
                  <Grid item>
                    <Typography variant="h6">{t('common.controlTimeSpent')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" className={classes.textBlack}>
                      {control.timeFormattedCounted}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (<Grid item />)}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          className={[classes.firstSectionSecondSubSection, classes.sectionBottomBorder].join(' ')}
        >
          <Grid item className={classes.marginBottom}>
            <Typography variant="h6" className={[classes.textBlack, classes.sectionTitle].join(' ')}>
              {t('common.vehicle')}
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between" className={classes.marginBottom}>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">
                  {t('common.model')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {vehicleType.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">
                  {t('common.nSerie')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {nSerie}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">
                  {t('common.nIntern')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {nIntern}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">
                  {t('common.plate')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {plate || t('common.not-available')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">
                  {t('common.client')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  { (client && client.name) || t('common.vehicleNew')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4} />
          </Grid>
        </Grid>
      </Grid>
      {Object.keys(itemsTableData).map((groupName, index) => {
        const data = {
          items: itemsTableData[groupName],
          range: {},
        }
        return (
          <Grid container className={classes.secondSection} direction="column" key={groupName}>
            <Grid item className={classes.secondSectionFirstSubSection}>
              <Grid container justifyContent="space-between" alignItems="flex-end">
                <Grid item>
                  <Typography variant="h6" className={[classes.textBlack, classes.sectionTitle].join(' ')}>
                    {groupName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.tableContainer}>
              <Table
                id={`table-${index}`}
                columns={itemsTableColumns}
                data={data}
              />
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}
